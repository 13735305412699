







































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { CommunityDetailViewModel } from '../../viewmodels/community-detail-viewmodel'
import { invitationController } from '../private-dao/dialogs/invite-dialog/invitation-controller'

@Observer
@Component({
  components: {
    'join-dao-button': () => import('@/modules/community/common/components/join-dao-button.vue'),
    'apply-to-join-button': () =>
      import('@/modules/community-organize/components/private-dao/apply-to-join-button.vue'),
    'apply-to-join-dialog': () =>
      import('@/modules/community-organize/components/private-dao/apply-to-join-dialog.vue'),
  },
})
export default class ProfileInfo extends Vue {
  @Inject({}) vm!: CommunityDetailViewModel

  invitationController = invitationController
  items = [
    { title: 'Public', value: 'public' },
    { title: 'Private', value: 'private' },
  ]

  goToSettingPage() {
    this.$router.push(`/community-organize/dao-detail/${this.vm.communityId}/setting`)
  }

  selectCoverImageInput() {
    if (!this.vm.isAdmin) return
    const coverImageInput = document.getElementById('cover-image-select') as any
    coverImageInput.click()
  }
}
