import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'

export type Screen = 'default' | 'invite-by-link' | 'create-new-link'
export class InvitationController {
  @observable openInvitationDialog = false
  @observable page = 0
  @observable userProfiles: ProfileModel[] = []
  @observable usersSelected: ProfileModel[] = []
  @observable userInviteFilter = { _limit: 20, _start: 0 }
  @observable userInviteFilterState = { _limit: 20, _start: 0 }
  @observable userInviteTotal = 0
  @observable userInviteLoadingMore = false
  @observable inviting = false

  @action.bound toInvitePage() {
    this.page = 0
  }

  @action.bound toCreateAPeriodPage() {
    this.page = 1
  }

  @action.bound toUserInvitePage() {
    this.page = 2
  }

  disposes: IReactionDisposer[] = []

  periodOptions = ['no limit', '1 Hour', '1 Day', '1 Week', 'Custom']

  constructor() {
    // this.fetchUserProfile()
    // this.fetchInviteLinks()
    this.disposes = [
      reaction(
        () => this.type,
        () => {
          if (this.type === 'no limit') {
            this.date = moment().add({ year: 99 })
          }
          if (this.type === '1 Hour') {
            this.date = moment().add({ hours: 1 })
          }
          if (this.type === '1 Day') {
            this.date = moment().add({ days: 1 })
          }
          if (this.type === '1 Week') {
            this.date = moment().add({ weeks: 1 })
          }
          if (this.type === 'Custom') {
            this.date = moment()
          }
        }
      ),
      reaction(
        () => this.userInviteFilter,
        (filter) => {
          this.userInviteFilterState = { _limit: 20, _start: 0 }
          this.fetchUserProfile()
        }
      ),
    ]
  }
  /**
   * CREATE A PERIOD
   */

  @asyncAction *loadData() {
    this.fetchUserProfile()
    this.fetchInviteLinks()
  }
  @observable links: any[] = []
  @observable defaultDaoLink?: any = undefined

  @observable linkName = ''
  @observable type = ''
  @observable date = moment()
  @observable linkCreating = false
  @observable inviteLinksFetching = false

  @action reset() {
    this.linkName = ''
    this.type = ''
    this.date = moment()
    this.usersSelected = []
    this.page = 0
  }

  @action.bound resetUsersSelected() {
    this.userProfiles = []
  }

  @action.bound changePeriod(value: string) {
    this.type = value
  }

  @action.bound changeDate(date: string) {
    const year = parseInt(date.substring(0, 4))
    const month = parseInt(date.substring(5, 7))
    const day = parseInt(date.substring(8, 10))
    this.date = this.date.clone().set({ year: year, month: month - 1, date: day })
  }

  @action.bound changeTime(time: string) {
    const hour = parseInt(time.substring(0, 2))
    const minute = parseInt(time.substring(3, 5))
    this.date = this.date.clone().set({ hour: hour, minute: minute })
  }

  @action.bound setOpenInvitationDialog(value: boolean) {
    if(value) {
      this.loadData()
    } else {
      this.reset()
    }
    this.openInvitationDialog = value
  }

  @computed get dateDisplay() {
    return this.date.format('YYYY-MM-DD')
  }

  @computed get timeDisplay() {
    return this.date.format('HH:mm')
  }

  @asyncAction *generateInviteLink(daoId) {
    try {
      this.linkCreating = true
      const daoId = appProvider.router.currentRoute.params.id
      const link = yield apiService.daos.generateInviteLink(daoId, {
        expiredTime: this.date.toISOString(),
        name: this.linkName,
      })
      if (link) {
        snackController.success('Link have created')
        this.links = [...this.links, link]
        this.toInvitePage()
        this.reset()
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.linkCreating = false
    }
  }

  @asyncAction *deleteInviteLink(linkItem: any) {
    try {
      linkItem.deleting = true
      yield apiService.daos.deleteInviteLink({ inviteLinkId: linkItem.id })
      this.links = this.links.filter((link) => link.id !== linkItem.id)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      linkItem.deleting = false
    }
  }

  @computed get disable() {
    return !this.linkName || !this.type
  }

  /**
   * INVITE USER
   */
  @observable searchText = ''
  @observable users: any[] = [
    { id: 1, name: 'A', wallet: 'A1FC' },
    { id: 2, name: 'B', wallet: 'A1FC' },
    { id: 3, name: 'C', wallet: 'A1FC' },
    { id: 4, name: 'D', wallet: 'A1FC' },
    { id: 5, name: 'E', wallet: 'A1FC' },
    { id: 6, name: 'F', wallet: 'A1FC' },
  ]

  @action.bound searchTextChange(text: string) {
    this.searchText = text
    this.changeInviteFilter({ ...this.userInviteFilter, unique_id_contains: text })
  }

  @action.bound changeInviteFilter(value: any) {
    this.userInviteFilter = value
  }

  @action.bound addUserSelected(user: ProfileModel) {
    const isUserSelected = this.usersSelected.filter((item) => item._id === user._id).length
    if (!isUserSelected) {
      this.usersSelected = [...this.usersSelected, user]
    }
  }

  @action.bound removeUserSelected(user: ProfileModel) {
    this.usersSelected = this.usersSelected.filter((item) => item._id !== user._id)
  }

  @action.bound copyLink(link: any) {
    const host = process.env.VUE_APP_HOST
    const linkInvitation = `${host}invite-link/${link.id}`
    navigator.clipboard.writeText(linkInvitation)
    snackController.success('Invite link has copied')
  }

  @asyncAction *fetchUserProfile() {
    try {
      const daoId = appProvider.router.currentRoute.params.id
      const res = yield apiService.daos.fetchUserToInvite(daoId, this.userInviteFilter)
      if (res) {
        this.userProfiles = res.data
        this.userInviteTotal = res.total
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchInviteLinks() {
    try {
      this.inviteLinksFetching = true
      const daoId = appProvider.router.currentRoute.params.id
      const res = yield apiService.daos.getInviteLinks(daoId, { _limit: -1, _start: 0 })
      if (res) {
        this.defaultDaoLink = res.data.find(link => link.isDefaultLink === true)
        this.links = res.data.filter(link => link.isDefaultLink !== true)
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.inviteLinksFetching = false
    }
  }

  @asyncAction *loadMoreUserInvite() {
    try {
      this.userInviteLoadingMore = true
      this.userInviteFilterState = {
        ...this.userInviteFilterState,
        _start: this.userInviteFilterState._start + this.userInviteFilterState._limit,
      }
      const daoId = appProvider.router.currentRoute.params.id
      const res = yield apiService.daos.fetchUserToInvite(daoId, { ...this.userInviteFilterState })
      const profileMore = res.data
      this.userProfiles = [...this.userProfiles, ...profileMore]
    } catch (error) {
    } finally {
      this.userInviteLoadingMore = false
    }
  }

  @asyncAction *inviteJoinDao() {
    try {
      this.inviting = true
      const daoId = appProvider.router.currentRoute.params.id
      const receivers = this.usersSelected.map((user) => user._id)
      if (receivers.length) yield apiService.daos.inviteJoinDao(daoId, { receivers: receivers as string[] })
      snackController.success('Invites have sent to users')
      this.setOpenInvitationDialog(false)
      this.resetUsersSelected()
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.inviting = false
    }
  }

  @computed get canLoadMoreUserInvite() {
    return this.userInviteFilterState._start + this.userInviteFilterState._limit < this.userInviteTotal
  }

  @computed get invitationButtonEnable() {
    return this.usersSelected.length
  }
}

export const invitationController = new InvitationController()
